<template>
  <div><div class="title">无忧代发</div><div style="text-align: center; line-height: 20px; margin-top: 10px;">
    发布时间：2022-04-11 20:02:11
  </div><div data-v-0ab10f90="" style="font-size: 14px;"><div>&nbsp;</div>
    <div>&nbsp;</div>
    <div><img src="https://www.houniao.hk/upload/image/20170407/20170407190710_78352.jpg" alt="" data-ke-src="/upload/image/20170407/20170407190710_78352.jpg">​</div>
    <div><img src="https://www.houniao.hk/upload/image/20170407/20170407190718_97764.jpg" alt="" data-ke-src="/upload/image/20170407/20170407190718_97764.jpg"></div>
    <div><img src="https://www.houniao.hk/upload/image/20170407/20170407190728_17116.jpg" alt="" data-ke-src="/upload/image/20170407/20170407190728_17116.jpg"></div>
    <div><img src="https://www.houniao.hk/upload/image/20170407/20170407190812_51694.jpg" alt="" data-ke-src="/upload/image/20170407/20170407190812_51694.jpg"></div>
    <div><img src="https://www.houniao.hk/upload/image/20170407/20170407190817_91033.jpg" alt="" data-ke-src="/upload/image/20170407/20170407190817_91033.jpg"></div></div></div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/11/15 10:21
 * @version 1.0
 * @description
 */
export default {
  name: 'DropShipping'
}
</script>

<style scoped>

</style>
